import React from 'react';
import Client from './Client';
import hvoImg from '../images/HVO.png';
import CreazaImg from '../images/Creaza.png';
import CodClusterImg from '../images/Codcluster.png';
import Oclin from '../images/Oclin.png';
import FilmKino from '../images/logos/filmkino.png';
import CrispinGlover from '../images/crispin-glover.svg';
import NRK from '../images/nrk-logo.png';
import IPR from '../images/logos/IPR.svg';
import SpareBank1 from '../images/logos/SpareBank1.svg';
import MidtnorskFilmSenter from '../images/logos/MNFS.png';
import MetallicaLogo from '../images/logos/Metallica-Logo.png';
import TrondheimKommune from '../images/logos/Trondheim_kommune.png';
import FLB from '../images/logos/FLB.png';
import NDLA from '../images/logos/ndla-logo.png';


const ClientSection = ({children}) => {
  return <div>
  <h1 className="mt-16 text-center md:mt-24 text-3xl text-gray-800" >Tidligere samarbeid</h1>

   <div className=" justify-center md:justify-between mb-8">
     <div className="flex flex-wrap justify-center">
      <Client img={MetallicaLogo}  width={'w-40'} className="pt-4"/>
      <Client img={NRK}/>
      <Client img={SpareBank1} width={'w-48'}/>
      <Client img={TrondheimKommune} width={'w-24'}/>
     

     </div>
     <div className="flex flex-wrap justify-center ">
      <Client img={FilmKino} width={'w-48'}/>
      <Client img={MidtnorskFilmSenter} width={'w-40'}/>
      <Client img={FLB} width={'w-40'}/>
    </div>
    <div className="flex flex-wrap justify-center ">
      <Client img={CrispinGlover} />
      <Client img={NDLA} />
      <Client img={CreazaImg}/>
    </div>
    <div className="flex flex-wrap justify-center">
      <Client img={hvoImg}/>
      <Client img={Oclin} />
      <Client img={CodClusterImg}/>
    </div>
  </div></div>
}

export default ClientSection;